@import './../../UXCVariables.css';

.uxc-comp-header-outer {
    background-color: #FFFFFF !important;
}
.uxc-comp-header-outer img {
    height:40px;
    width:auto;
}
.uxc-comp-header-outer svg {
    fill:var(--uxc-color-blue-dark) !important;
}
.uxc-comp-header-button {
    background-color:#fff !important;
    color:var(--uxc-color-blue-dark) !important;
}
.uxc-comp-header-button:hover {
    background-color:#fff !important;
    color:var(--uxc-color-blue-med) !important;
}
