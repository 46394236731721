.uxc-comp-card-outer {
    text-align:left;
}
.uxc-comp-card-outer h3 {
    font-size:1.5rem;
    line-height:1.33333333;
    font-weight:500;
    margin-top:0px !important;
}
.uxc-comp-cardcontent {
    padding:0px !important;
}
.uxc-comp-card-lower {
    padding:16px;
}